import React from "react";
import { t } from "i18next";
import Article from "shared/Article.jsx";

import { readState } from "@/__main__/app-state.mjs";
import type { Meta } from "@/__main__/router.mjs";
import { GAME_FRIENDLY_SHORT_NAME_MAP } from "@/app/constants.mjs";
import { GAME_SYMBOL_TFT } from "@/game-tft/definition-symbol.mjs";

export default function TFTArticle() {
  return <Article gameSymbol={GAME_SYMBOL_TFT} />;
}

export function meta([id]): Meta {
  const {
    contentful: { entry, canonical },
  } = readState;
  const article = entry[id] ?? entry[canonical[id]];
  if (
    typeof article?.seoTitle === "string" &&
    typeof article?.seoDescription === "string"
  ) {
    return {
      title: ["", article.seoTitle],
      description: ["", article.seoDescription],
    };
  }

  const friendlyName = t(...GAME_FRIENDLY_SHORT_NAME_MAP[GAME_SYMBOL_TFT]);
  return {
    title: [
      "contentful:article.meta.title",
      "{{friendlyName}} - {{id}} - Blitz",
      { friendlyName, id },
    ],
    description: [
      "contentful:article.meta.description",
      "Master {{friendlyName}} with expert tips for {{id}}, in-depth guides, and winning strategies from Blitz.gg. Whether you're a beginner or a pro, we've got everything you need to level up your game!",
      { friendlyName, id },
    ],
  };
}
